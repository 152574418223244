import React from "react";
import PropTypes from "prop-types";
import {Nav } from "react-bootstrap";

export default class ToggleTabs extends React.Component {
  render() {
    const { activityTypes, message } = this.props;

    return (
        <Nav
          variant="tabs"
          onSelect={(eventKey) => this.props.onChange(eventKey)}
          style={{ borderBottomWidth: "0px", color: "black" }}S
        >
          {Array.from(activityTypes).map((activityType, index) =>
            activityType == "Meddelanden" ? (
              <Nav.Item>
                <Nav.Link
                key={index}
                eventKey={activityType}
                title={message.length + " " + activityType}
                disabled={message.length <= 0}
              >{activityType}</Nav.Link>
              </Nav.Item>
            ) : (
              <Nav.Item><Nav.Link key={index} eventKey={activityType} title={activityType}>{activityType}</Nav.Link></Nav.Item>
            )
          )}
        </Nav>
    );
  }
}
ToggleTabs.propTypes = {
  onChange: PropTypes.func,
  activityTypes: PropTypes.array,
  message: PropTypes.array,
};
